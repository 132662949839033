import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form } from "formik";
import Cookies from "js-cookie";
import * as Yup from 'yup';
import firebase from "../../../Firebase/Firebase";
import { firebaseService } from "../../../Firebase";
import FormikControl from "../../../Formik/FormikControl";
// import FacebookLogin from 'react-facebook-login';
// import { GoogleLogin } from 'react-google-login';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


toast.configure()
function LoginModal(props) {

  const [showOtp, setshowOtp] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [verifiedTocken, setVerifiedTocken] = useState();
  const { showToastMessage, resetToastMessage } = props;
  const [phoneNo, setPhoneNo] = useState();
  const [ResendOtp, setResendOtp] = useState();
  const [countryCode, setCountryCode] = useState('+974');

 const openSignupModal = () => {
  console.log("here")
 
 props.openSignup()

 }
 ;
 console.log(props.openSignup,"valueNo557")
  const setFieldValue = (value) => {
    console.log(props,"valueNo557")
    setResendOtp(value);
    console.log(ResendOtp,"valueNo557")
  }

  const toggle = () => {
    props.closeLogin();
  };

  const toggleTabs = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const initialValues = {
    email: '',
    password: '',
    client_id: "web",
    client_secret: "5bd08540-aa03-4f1a-9071-2d1c3fd8c118"
  }
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required'),
  })
  const initialValuesOtp = {
    mobile: '',
    client_id: "web",
    client_secret: "5bd08540-aa03-4f1a-9071-2d1c3fd8c118"
  }
  const validationSchemaMobile = Yup.object({
    mobile: Yup.string().required('Required'),

  })
  const validationSchemaOtp = Yup.object({
    mobile: Yup.string().required('Required'),
    otp: Yup.string().required('Required'),

  })

  const onSubmitOtp = (values, onSubmitProps) => {
    console.log(values,"onSubmitOtp")
 
    if (values.mobile && values.otp === undefined) {
      sendOtpToUser(values, onSubmitProps)
   
    }
    else {
     
      loginWithAuth(values, onSubmitProps)
    }
  }

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" }
    );
  }, []);

  const sendOtpToUser = (values, onSubmitProps) => {
    var phoneNumber = JSON.stringify(countryCode+values.mobile);
    console.log(phoneNumber,"phoneNumber")
    var appVerifier = window.recaptchaVerifier;
    setResendOtp(phoneNumber)
    firebaseService.signInWithPhone(phoneNumber, appVerifier, showToastMessage, resetToastMessage, setshowOtp, onSubmitProps, setVerifiedTocken);
  };

  const loginWithAuth = (values, onSubmitProps) => {
    var code = JSON.stringify(values.otp)
    verifiedTocken
      .confirm(code)
      .then(function (result) {
        Cookies.set("kees_customer_access_token", result.user._lat, { expires: 4 });
        showToastMessage({ type: "success", message: "Logged in Succesfully" });
        resetToastMessage();
        toggle();
      })
      .catch(function (error) {
        onSubmitProps.setSubmitting(false)
        toast.error(error.message)
      });

    ;
  };

  const onSubmit = (values, onSubmitProps) => {
    console.log(values, "onSubmitProps")
    firebase.auth().signInWithEmailAndPassword(values.email, values.password).then((u) => {
      console.log(u)
      toast.success("Logged in succesfully")
      Cookies.set("kees_customer_access_token", u.user._lat, { expires: 4 });
      setTimeout(() => {
      }, 1000);
      toggle();
    }).catch((error) => {
      onSubmitProps.setSubmitting(false)
      toast.error( error.message);
    });
  };

  const googleButton = (response) => {
    // var provider = new firebase.auth.FacebookAuthProvider();
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        console.log(result, 'token')
        var token = result.user._lat;
        console.log(token, 'token')
        toast.success("Logged in succesfully")
        Cookies.set("kees_customer_access_token", token, { expires: 4 });
        setTimeout(() => {
        }, 1000);
        toggle();
        // ...
      }).catch(function (error) {
        toast.error( error.message);
      });
  }
  const facebookButton = (response) => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        console.log(result)
        var token = result.user._lat;
        toast.success("Logged in succesfully")
        Cookies.set("kees_customer_access_token", token, { expires: 4 });
        setTimeout(() => {
        }, 1000);
        toggle();
        // ...
      }).catch(function (error) {
        toast.error( error.message);
      });
  }
 const resetPhone =()=>{
   
   setshowOtp(false)
   console.log(showOtp,"showOtp")
   return 
 }
 const handleCountryCode=(e)=>{
setCountryCode(e.target.value)
console.log(countryCode,"handleCountryCode")
 }
  return (
    <div>
      <input
        id="recaptcha-container"
        type="button"
        style={{ display: "none" }}
      />
      <Modal
        isOpen={props.show}
        size="lg"
        toggle={toggle}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="defaultPopup"
      >
        {/* <button toggle={toggle}>x</button> */}
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle}></ModalHeader>
        <ModalBody closeButton className="defaultPopup">
          <div className="modal-body ">
            <div className="container">
              <div className="row">
                <div className="col-sm-7">
                  <div className="popupTabs">
                    <Nav tabs variant="pills" className="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                      <NavItem className="nav-item">
                        <NavLink className={activeTab === '1' ? 'active nav-link' : "nav-link"} onClick={() => { toggleTabs('1'); }} id="pills-login-tab"><strong>Login</strong></NavLink>
                      </NavItem>
                      <NavItem className="nav-item">
                        <NavLink className={activeTab === '2' ? 'active nav-link' : "nav-link"} onClick={() => { toggleTabs('2'); }} id="pills-loginotp-tab"><strong>Login Via OTP</strong></NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="login_form">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                          >
                            {formik => {
                              return (
                                <Form className="row contact_form" action="#" method="post" noValidate="novalidate">
                                  <div className="col-md-12 form-group p_star">
                                    <input
                                      id="recaptcha-container"
                                      type="button"
                                      style={{ display: "none" }}
                                    />
                                    <FormikControl
                                      style={{ borderRadius: "50px" }}
                                      control='input'
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      name="email"

                                      placeholder="Username" />
                                  </div>
                                  <div className="col-md-12 form-group p_star">
                                    <FormikControl
                                      style={{ borderRadius: "50px" }}
                                      control='input'
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      name="password"

                                      placeholder="Password" />
                                  </div>
                                  <div className="col-md-12 form-group">
                                    <div className="creat_account d-flex align-items-center">
                                      <FormikControl
                                        type="checkbox"

                                        id="f-option"
                                        name="selector" />
                                      <label htmlFor="f-option">Remember me</label>
                                    </div>
                                    
                                    <button type="submit" value="submit" className="btn btn-dark btn-rounded" disabled={!formik.isValid || formik.isSubmitting} >
                                      log in
                          </button>
                                    <Link className="lost_pass" href="#">forget password?</Link>
                                  </div>
                                </Form>
                              )
                            }
                            }
                          </Formik>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="login_form">
                          <Formik
                            initialValues={initialValuesOtp}
                            validationSchema={showOtp ? validationSchemaOtp : validationSchemaMobile}
                            onSubmit={onSubmitOtp}
                          >
                            {
                              formik => {
                                {console.log(countryCode,"handleCountryCode")}
                                return (
                                  <Form className="row contact_form" action="#" method="post">
                                    <div className="col-md-12 input-group append-group mb-3">
                                    <div className="input-group-prepend">
                                                <select className="input-group-text" id="inputGroupSelect01" onChange={handleCountryCode} value={countryCode} >
                                                     <option value="+91" selected>+91</option> 
                                                    <option value="+974">+974</option>
                                                </select>
                                               
                                      
                                    </div>
                                    <FormikControl
                                        control='input'
                                        type="text"
                                        className="appendInput"
                                        id="name"
                                        name="mobile"
                                        disabled={showOtp}
                                        placeholder="Mobile Number"
                                      />
                                     
                                      <FormikControl
                                        control='input'
                                        type="number"
                                        className="form-control"
                                        id="name"
                                        name="otp"
                                        style={{ display: showOtp ? 'inline-block' : 'none', borderRadius: "50px", marginTop: '20px' }}
                                        defaultValue={''}
                                        placeholder="OTP"
                                      />
                                    </div>
                                    <div className="col-md-12 form-group">
                                      <button type="submit" value="submit" style={{ display: showOtp ? 'none' : 'inline-block' }} className="btn btn-dark btn-rounded" disabled={!formik.isValid || formik.isSubmitting} >
                                        Send OTP
                          </button>
                          <button type="button" onClick={resetPhone} style={{ display: showOtp ? 'inline-block' : 'none' }} className="btn btn-dark btn-rounded mr-2">Edit Mobile</button>
                                      <button type="submit" value="submit" style={{ display: showOtp ? 'inline-block' : 'none' }} className="btn btn-dark btn-rounded" disabled={ formik.isSubmitting}  >
                                        Submit
                                       </button>
                                       </div>
                                       <div className="col-md-12 form-group">
                                      <button type="button" 
                                      onClick={()=>firebaseService.signInWithPhone(ResendOtp, window.recaptchaVerifier, showToastMessage, resetToastMessage, setshowOtp, formik, setVerifiedTocken)}
                                      value="submit" style={{ display: showOtp ? 'inline-block' : 'none' }} 
                                        className="btn btn-sm btn-warning btn-rounded"
                                         disabled={ formik.isSubmitting}>
                                        Resend OTP
                                      </button>
                                    </div>
                                  </Form>
                                )
                              }
                            }
                          </Formik>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
                <div className="col-sm-5 mb-4">
                  <h4 className="mt-3">Social Login</h4>
                  <p className="my-3">Quickly Signin with your social network</p><p>
                    {/* <GoogleLogin
                      clientId="665441066505-jl86gbqcegg8e71a2j0iko60g0ir61j8.apps.googleusercontent.com"
                      onSuccess={responseGoogle}
                      autoLoad={false}
                      onFailure={responseGoogleFailure}
                      cookiePolicy={'single_host_origin'}
                      render={renderProps => (
                        <button onClick={renderProps.onClick} className="btn btn-warning btn-block btn-rounded mb-3" disabled={renderProps.disabled}><i class="ti-email float-left mt-1"></i> Sign In With Google</button>
                      )}
                    /> */}
                    <button onClick={googleButton} type="submit" value="submit" class="btn btn-warning btn-block btn-rounded mb-3">
                      <i class="ti-email float-left mt-1"></i>  Google
                                    </button>
                    <button onClick={facebookButton} type="submit" value="submit" class="btn btn-warning btn-block btn-rounded">
                      <i class="ti-facebook float-left mt-1"></i> Facebook
                                    </button>
                    {/* <FacebookLogin
                      appId="201689614591653"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      cssClass="btn btn-warning btn-block btn-rounded"
                      icon={<i class="ti-facebook float-left mt-1"></i>}
                    /> */}


                  </p></div>
                <div className="col-md-12 text-center pt-3 border-top">
                  New to Kees? <Link onClick={openSignupModal} className="btn btn-sm btn-warning btn-rounded">Sign Up</Link>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}




export default LoginModal
