import firebase from './Firebase';

export default class FirebaseService {
    auth = firebase.auth();

    async signIn(email, password) {
        try {
            return await this.auth.signInWithEmailAndPassword(email, password);
        } catch (error) {
            return { error };
        }
    }

    async signInWithFacebook(credential) {
        try {
            return await this.auth.signInWithCredential(credential);
        } catch (error) {
            return { error };
        }
    }

    async signUp(email, password) {
        try {
            return await this.auth.createUserWithEmailAndPassword(email, password);
        } catch (error) {
            return { error };
        }
    }

    async signInWithPhone(phoneNumber, appVerifier, showToastMessage, resetToastMessage, setshowOtp, onSubmitProps, setVerifiedTocken, setStopLoader, setStartLoader) {
        try {
            // setStartLoader();
            return await this.auth.signInWithPhoneNumber(phoneNumber, appVerifier).then(response => {
                window.response = response;
                setVerifiedTocken(response);
                setshowOtp(true);
                onSubmitProps && onSubmitProps.setSubmitting(false);
                showToastMessage({ type: "success", message: "OTP sent to registered Mobile" });
                resetToastMessage();
            });
        } catch (error) {
            // setStopLoader();
            showToastMessage({type:"error",message:error.message});
            onSubmitProps && onSubmitProps.setSubmitting(false);
            resetToastMessage();
            return { error }
        }
    }
}
