import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { get as _get } from "lodash";
import UserProfile from './UserProfile';
import { getProductList } from '../../Store/action/product'
import { showToastMessage, setStartLoader, setStopLoader, resetToastMessage } from '../../Store/action/loader'
import { openLogin, closeLogin } from '../../Store/action/login';
import { logoutUser } from '../../Store/action/user'

function mapStateToProps(state, props) {
    return {
        showLogin : state.login.showLogin,
        userLocationName : state.location.userLocationName,
        userProfile:_get(state,'user.userProfile',null),
        cartCount: state.product.addToCart ? state.product.addToCart.products.length : 0,
        addToCart: state.product.addToCart,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProductList,
        showToastMessage,
        setStartLoader, 
        setStopLoader,
        resetToastMessage,
        openLogin,
        closeLogin,
        logoutUser
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile);

