import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, ModalHeader } from 'reactstrap';
import FormikControl from "../../../Formik/FormikControl";
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import Cookies from "js-cookie";
import firebase from "../../../Firebase/Firebase";
import * as Yup from "yup";
import { getToken } from '../../../utils/token';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moduleName from 'module'

const SignupModal = (props) => {
    // const [toggle, setToggle] = useState(false);
    const toggle = () => {
        props.closeSignup();
      };

      const openLoginModal = () => {
        props.closeSignup();  
        props.openLogin();}
      const initialValues = {
        name:'',  
        email: '',
        password: '',
        cpassword: '',
    
      }
      const validationSchema = Yup.object({
        // name:Yup.string().required('Required'), 
        email: Yup.string().email('Invalid email format').required('Required'),
        password: Yup.string().required('Required'),
        cpassword:  Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),

      })
      const onSubmit = (values, onSubmitProps) => {
       
        firebase.auth().createUserWithEmailAndPassword(values.email, values.password).then((u) => {
            console.log(u)
            debugger;
            toast.success("Signed Up succesfully")
            Cookies.set("kees_customer_access_token", u.user._lat, { expires: 4 });
            onSubmitProps.setSubmitting(false)
            setTimeout(() => {
              // props.history.push('/user-profile/my-profile')
            }, 1000);
            if (getToken() && props.userProfile === null) {
              props.getUserProfile()
            }
            toggle();
          }).catch((error) => {
            onSubmitProps.setSubmitting(false)
            toast.error( error.message);
          });
    
        }
        const googleButton = (response) => {
            // var provider = new firebase.auth.FacebookAuthProvider();
            const provider = new firebase.auth.GoogleAuthProvider();
        
            firebase
              .auth()
              .signInWithPopup(provider)
              .then(function (result) {
                console.log(result, 'token')
                var token = result.user._lat;
                console.log(token, 'token')
                toast.success("Logged in succesfully")
                Cookies.set("kees_customer_access_token", token, { expires: 4 });
                setTimeout(() => {
                }, 1000);
                toggle();
                // ...
              }).catch(function (error) {
                toast.error( error.message);
              });
          }
          const facebookButton = (response) => {
            var provider = new firebase.auth.FacebookAuthProvider();
            firebase
              .auth()
              .signInWithPopup(provider)
              .then(function (result) {
                console.log(result)
                var token = result.user._lat;
                toast.success("Logged in succesfully")
                Cookies.set("kees_customer_access_token", token, { expires: 4 });
                setTimeout(() => {
                 
                }, 1000);
                toggle();
                // ...
              }).catch(function (error) {
                toast.error( error.message);
              });
          }
    return (
        <div>
            {console.log(props,"props")}
               <Modal
        isOpen={props.show}
        size="lg"
        toggle={toggle}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="defaultPopup"
      >
        {/* <button toggle={toggle}>x</button> */}
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle}></ModalHeader>
        <ModalBody closeButton className="defaultPopup">
        <div className="modal-body">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <h4 className="mt-3 mb-3">Sign Up to Kees</h4>
              <div className="login_form">
                  <Formik
                     initialValues={initialValues}
                     validationSchema={validationSchema}
                     onSubmit={onSubmit}
                  >
                       {formik => {
                           console.log(formik,"formik2")
                              return (
                <Form className="row contact_form" action="#" method="post" noValidate="novalidate">
                  {/* <div className="col-md-12 form-group p_star">
                    <FormikControl control="input" type="text" className="form-control" id="name" name="name" defaultValue placeholder="Name" />
                  </div> */}
                  <div className="col-md-12 form-group p_star">
                    <FormikControl control="input"  type="text" className="form-control" id="name" name="email" defaultValue placeholder="Email Address" />
                  </div>
                  <div className="col-md-12 form-group p_star">
                    <FormikControl control="input"  type="password" className="form-control" id="password" name="password" defaultValue placeholder="Password" />
                  </div>
                  <div className="col-md-12 form-group p_star">
                    <FormikControl control="input"  type="password" className="form-control" id="password" name="cpassword" defaultValue placeholder="Confirm Password" />
                  </div>
                  {/* <div className="col-md-12 form-group p_star">
                    <FormikControl type="date" className="form-control" id="dob" name="dob" defaultValue placeholder="Date of Birth" />
                  </div> */}
                  <div className="col-md-12 form-group">
                    <div className="creat_account d-flex align-items-center">
                      <input type="checkbox" id="f-option" name="selector" />
                      <label htmlFor="f-option">Remember me</label>
                    </div>
                    
                    <button type="submit" value="submit" className="btn btn-dark btn-rounded"disabled={!formik.isValid || formik.isSubmitting}  >
                      Sign Up
                    </button>
                  </div>
                </Form>
                              )}}
                </Formik>
              </div>
            </div>
            <div className="col-sm-5 mb-4">
              <h4 className="mt-3">Social Login</h4>
              <p className="my-3">Quickly Signin with your social network</p><p>
                <button onClick={googleButton}  type="submit" value="submit" className="btn btn-warning btn-block btn-rounded mb-3">
                  <i className="ti-email float-left mt-1" />  Google
                </button>
                <button onClick={facebookButton} type="submit" value="submit" className="btn btn-warning btn-block btn-rounded">
                  <i className="ti-facebook float-left mt-1" /> Facebook
                </button>
              </p></div>
            <div className="col-md-12 text-center pt-3 border-top">
              Already have an account? <a onClick={openLoginModal} href="#" data-toggle="modal" data-target="#loginpopup" className="btn btn-sm btn-warning btn-rounded">Login</a>
            </div>
          </div>
        </div></div>
        </ModalBody>
        </Modal>
        </div>
    )
}
export default withRouter(SignupModal);

