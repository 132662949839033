import {
    SET_BANNER_SUCCESS,
    SET_BANNER_ERROR
} from "../actionType";
const INITIAL_STATE = {
    bannerList: null,
    bannerListError: null
};
const category = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_BANNER_SUCCESS:
            return {
                ...state,
                bannerList: action.payload.data.data,
                bannerListError: null,
            };
        case SET_BANNER_ERROR:
            return {
                ...state,
                bannerList: action.payload,
                bannerListError: null,
            };
        default:
            return state
    }
};
export default category;