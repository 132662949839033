// import firebase from "../Firebase/Firebase";
import Cookies from 'js-cookie';
export const getToken = () => {
    let allcookies = document.cookie.split(';');
    let token = ""
    for (let i = 0; i < allcookies.length; i++) {
        if (allcookies[i].split('=')[0].trim() === 'kees_customer_access_token') {
            token = allcookies[i].split('=')[1];
        }
    }
    // let token = "";
    // let user = firebase.auth().currentUser;
    // console.log('user', user);
    // if(user) {
    //     token = user._lat;
    // }else{
    //     firebase.auth().signOut()
    //     return false;
    // }
    return token
}

export const logout = () => {
    Cookies.remove('kees_customer_access_token')
    // return firebase.auth().signOut();
}