import {
    GET_FAQ_SUCCESS,
    GET_FAQ_ERROR
} from "../actionType";
const INITIAL_STATE = {
    faqList: null,
    faqListError: null
};
const faq = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_FAQ_SUCCESS:
            return {
                ...state,
                faqList: action.payload.data.data,
                faqListError: null,
            };
        case GET_FAQ_ERROR:
            return {
                ...state,
                faqList: action.payload,
                faqListError: null,
            };
        default:
            return state
    }
};
export default faq;