import {
    API_INVOCATION,

    OPEN_LOCATION,
    CLOSE_LOCATION,

    OPEN_FINETUNE_LOCATION,
    CLOSE_FINETUNE_LOCATION,

    SET_USER_LOCATION,

    GET_RANGE,
    CHECK_DELIVERY,
} from "../../actionType";
import * as constdata from '../../../utils/constants';
import * as consturl from '../../../utils/url';

export const openLocation = _payload => {
    return { type: OPEN_LOCATION, payload: _payload }
}

export const closeLocation = _payload => {
    return { type: CLOSE_LOCATION, payload: _payload }
}

export const openFinetuneLocation = _payload => {
    return { type: OPEN_FINETUNE_LOCATION, payload: _payload }
}

export const closeFinetuneLocation = _payload => {
    return { type: CLOSE_FINETUNE_LOCATION, payload: _payload }
}

export const setUserLocation = _payload => {
    return { type: SET_USER_LOCATION, payload: _payload }
}

/**
 * 
 *  get activity details  
 */
export const getDeliveryRange = (_payload, resolve, reject) => {
    const url = `${consturl.BASE_URL}${consturl.GETRANGE}`;
    const data = {
        geo_latitude:  _payload.geo_latitude,
        geo_longitude: _payload.geo_longitude
    }
    const payload = {
        action: GET_RANGE,
        method: constdata.POST,
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        url,
        data,
        resolve,
        reject
    };

    return { type: API_INVOCATION, payload};
}
export const checkDeliveryRequest = (_payload, resolve, reject) => {
   
    const url = `${consturl.BASE_URL}${consturl.CHECK_DELIVERY_REQUEST}`;
    const data = {
      
        email: _payload.email,
        mobile:_payload.mobile,
        geo_address: _payload.geo_address,
        geo_latitude: _payload.geo_latitude,
        geo_longitude: _payload.geo_longitude,
        
    }
    const payload = {
        action: CHECK_DELIVERY,
        method: constdata.POST,
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        data,
        url,
        resolve, 
        reject
    };

    return { type: API_INVOCATION, payload };
};