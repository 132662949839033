import React from 'react'

const Banner = () => {
  return (
    <div>
      <section className="banner_part">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 my-5 text-center">
              <h2>My Account</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Banner
