import {combineReducers} from 'redux';
import product from "./product";
import category from "./category";
import banner from "./banner";
import loader from "./loader";
import login from "./login";
import location from "./location";
import contact from "./contact";
import user from "./user";
import faq from "./faq";
import cart from "./cart";
import checkout from "./checkout";


const rootReducer = combineReducers({
    product,
    category,
    banner,
    loader,
    login,
    location,
    contact,
    user,
    faq,
    cart,
    checkout,
    
});

export default rootReducer;