import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import { withRouter } from "react-router";
import { REACT_APP_GEO_LOCATION } from '../../../utils/constants';
// import { GoogleMapsAPI } from '../client-config';
Geocode.setApiKey( REACT_APP_GEO_LOCATION );
Geocode.enableDebug();

class Map extends Component{

	constructor( props ){
		super( props );
		this.state = {
			address: '',
			city: '',
			area: '',
			state: '',
			mapPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng,
				  
			},
			markerPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			}
		}
	}
	/**
	 * Get the current address from the default map position and set those values in the state
	 */
	componentDidMount() {
        console.log("componentDidMount")
		Geocode.fromLatLng( this.state.mapPosition.lat , this.state.mapPosition.lng ).then(
			response => {
                console.log(response,"response")
				const address = response.results[0].formatted_address,
				      addressArray =  response.results[0].address_components,
				      city = this.getCity( addressArray ),
				      area = this.getArea( addressArray ),
				      state = this.getState( addressArray );

				console.log( 'city', city, area, state );

				this.setState( {
					address: ( address ) ? address : '',
					area: ( area ) ? area : '',
					city: ( city ) ? city : '',
					state: ( state ) ? state : '',
				} )
			},
			error => {
				console.error( error );
			}
		);
	};
	/**
	 * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
	 *
	 * @param nextProps
	 * @param nextState
	 * @return {boolean}
	 */
   
	shouldComponentUpdate( nextProps, nextState ){
		if (
			this.state.markerPosition.lat !== this.props.center.lat ||
			this.state.address !== nextState.address ||
			this.state.city !== nextState.city ||
			this.state.area !== nextState.area ||
            this.state.state !== nextState.state
            
		) {
			console.log(this.state.addres,'address')
            this.props.updateGeoLocation(this.state.mapPosition,this.state.address)
                
             
			return true
		} else if ( this.props.center.lat === nextProps.center.lat ){
			return false
		}
	}
	componentDidUpdate(prevProps,prevState)
	{
		if(prevState.address!==this.state.address)
		{
			this.props.updateGeoLocation(this.state.mapPosition,this.state.address)
		}
	}
	/**
	 * Get the city and set the city input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getCity = ( addressArray ) => {
		let city = '';
		for( let i = 0; i < addressArray && addressArray.length; i++ ) {
			if ( addressArray[ i ].types[0] && 'administrative_area_level_2' === addressArray[ i ].types[0] ) {
				city = addressArray[ i ].long_name;
				return city;
			}
		}
	};
	/**
	 * Get the area and set the area input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getArea = ( addressArray ) => {
		let area = '';
		for( let i = 0; i < addressArray && addressArray.length; i++ ) {
			if ( addressArray[ i ].types[0]  ) {
				for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
					if ( 'sublocality_level_1' === addressArray[ i ].types[j] || 'locality' === addressArray[ i ].types[j] ) {
						area = addressArray[ i ].long_name;
						return area;
					}
				}
			}
		}
	};
	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getState = ( addressArray ) => {
		let state = '';
		for( let i = 0; i < addressArray && addressArray.length; i++ ) {
			for( let i = 0; i < addressArray.length; i++ ) {
				if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
					state = addressArray[ i ].long_name;
					return state;
				}
			}
		}
	};
	/**
	 * And function for city,state and address input
	 * @param event
	 */
	onChange = ( event ) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	/**
	 * This Event triggers when the marker window is closed
	 *
	 * @param event
	 */
	onInfoWindowClose = ( event ) => {

	};

	/**
	 * When the marker is dragged you get the lat and long using the functions available from event object.
	 * Use geocode to get the address, city, area and state from the lat and lng positions.
	 * And then set those values in the state.
	 *
	 * @param event
	 */
	onMarkerDragEnd = ( event ) => {
        console.log(event,"eventTrue")
		let newLat = event.latLng.lat(),
		    newLng = event.latLng.lng();

		Geocode.fromLatLng( newLat , newLng ).then(
			response => {
				console.log(response,"eventTrue")
				const address = response.results[0].formatted_address,
				      addressArray =  response.results[0].address_components,
				      city = this.getCity( addressArray ),
				      area = this.getArea( addressArray ),
				      state = this.getState( addressArray );
				this.setState( {
					address: ( address ) ? address : '',
					area: ( area ) ? area : '',
					city: ( city ) ? city : '',
					state: ( state ) ? state : '',
					markerPosition: {
						lat: newLat,
						lng: newLng
					},
					mapPosition: {
						lat: newLat,
						lng: newLng
					},
				} )
			},
			error => {
				console.error(error);
			}
		);
	};

	/**
	 * When the user types an address in the search box
	 * @param place
	 */
	onPlaceSelected = ( place ) => {
		console.log( 'plc', place );
		const address = place.formatted_address,
		      addressArray =  place.address_components,
		      city = this.getCity( addressArray ),
		      area = this.getArea( addressArray ),
		      state = this.getState( addressArray ),
		      latValue = place.geometry && place.geometry.location.lat(),
		      lngValue = place.geometry && place.geometry.location.lng();
		// Set these values in the state.
		this.setState({
			address: ( address ) ? address : '',
			area: ( area ) ? area : '',
			city: ( city ) ? city : '',
			state: ( state ) ? state : '',
			markerPosition: {
				lat: latValue,
				lng: lngValue
			},
			mapPosition: {
				lat: latValue,
				lng: lngValue
			},
		})
	};

	changeAddressAutocomplete=(e)=>{
      this.setState({address:e.target.value})
	}
	render(){
		console.log(this.state.address,"address")
		const AsyncMap = withScriptjs(
            
			withGoogleMap(
                
                props =>{
					{console.log(this.state.mapPosition,"this.state.mapPosition")}
                    return(
                   <div>
					   { 
					<GoogleMap google={ this.props.google }
					           defaultZoom={ this.props.zoom }
					           defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
					>
                         
						{/* InfoWindow on top of marker */}
						{/* <InfoWindow
							onClose={this.onInfoWindowClose}
							position={{ lat: ( this.state.markerPosition.lat + 0.0018 ), lng: this.state.markerPosition.lng }}
						>
							<div>
								<span style={{ padding: 0, margin: 0 }}>{ this.state.address }</span>
							</div>
						</InfoWindow> */}
						{/*Marker*/}
                        <Marker 
                                google={this.props.google}
						        name={'Current location'}
						        draggable={true}
                                onDragEnd={ this.onMarkerDragEnd }
                                position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                                
						/>
						<Marker />
						{/* For Auto complete Search Box */}
				
					</GoogleMap>
				}
					</div>
				)}
			)
		);
		let map;
		if( this.props.center.lat !== undefined ) {
			map = <div>
				
			
                     <div className="row">
                    
                    <div className="form-group col-md-12">
					<Autocomplete
						    className="form-control"
							onPlaceSelected={ this.onPlaceSelected }
							types={['address']}
							// value={this.state.address}
							defaultValue={ this.state.address
							 }
							//  onChange={this.changeAddressAutocomplete}
						
						/>
					</div>
                    </div>
				
					<AsyncMap
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GEO_LOCATION}&libraries=places`}
					loadingElement={
						<div style={{ height: `300px` }} />
					}
					containerElement={
						<div style={{ height: `300px` }} />
					}
					mapElement={
						<div style={{ height: `300px` }} />
					}
				/>
				</div>

			
			
		} else {
			map = <div>
				<h5>We can't find your current location, please enter your location</h5>
						<Autocomplete
						    className="form-control"
							onPlaceSelected={ this.onPlaceSelected }
                            types={['address']}
                            defaultValue={ this.state.address }
						/>
			</div>
		}
		return( map )
	}
}

export default withRouter(Map);