import {
    OPEN_LOGIN,
    CLOSE_LOGIN,
    OPEN_SIGNUP,
    CLOSE_SIGNUP
} from "../actionType";
const INITIAL_STATE = {
    showLogin: false,
    showSignup:false,
};
const login = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_LOGIN:
            
            return {
                ...state,
                showLogin: true,
            };
        case CLOSE_LOGIN:
            return {
                ...state,
                showLogin: false,
            };
            case OPEN_SIGNUP:
                return {
                    ...state,
                    showLogin: false,
                    showSignup:true
                };
                case CLOSE_SIGNUP:
                    return {
                        ...state,
                        showLogin: false,
                        showSignup:false
                    };
        default:
            return state
    }
};
export default login;