import { toast } from 'react-toastify'
import { delete_cookie } from "../../utils/commonFunction";
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css'

const ToastMessage = ({ toastData,openLogin }) => {
    switch (toastData && toastData.type) {
        case "success":
            return toast.success(toastData.message)
        case "error":
            
            if(toastData.message==="Network Error")
           {
                openLogin({})
                Cookies.remove('kees_customer_access_token');
                delete_cookie("kees_customer_access_token")
            }
            return toast.error(toastData.message)
        case "warning":
            return toast.warn(toastData.message)
        default:
            return null
    }
}

export default ToastMessage;
