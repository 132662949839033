import {
    OPEN_LOCATION,
    CLOSE_LOCATION,

    OPEN_FINETUNE_LOCATION,
    CLOSE_FINETUNE_LOCATION,

    GET_RANGE_SUCCESS,
    GET_RANGE_ERROR,

    SET_USER_LOCATION,
    CHECK_DELIVERY_SUCCESS,
    CHECK_DELIVERY_ERROR
} from "../actionType";

const INITIAL_STATE = {
    showLocation: false,
    showFinetuneLocation: false,
    isDeliveryRangeAvailable: false,
    userLat: null,
    userLng: null,
    userLocationName: null,
    deliveryRange: null,
    deliveryRangeError: null,
    deliveryData: null,
    deliveryDataError: null
};
const login = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_LOCATION:
            return {
                ...state,
                showLocation: true,
            };
        case CLOSE_LOCATION:
            return {
                ...state,
                showLocation: false,
            };

        case OPEN_FINETUNE_LOCATION:
            return {
                ...state,
                showFinetuneLocation: true,
            };
        case CLOSE_FINETUNE_LOCATION:
            return {
                ...state,
                showFinetuneLocation: false,
            };

        case GET_RANGE_SUCCESS:
            return {
                ...state,
                isDeliveryRangeAvailable: action.payload.data.delivery_status,
                deliveryRange: action.payload.data,
                deliveryRangeError: null,
            };
        case GET_RANGE_ERROR:
            return {
                ...state,
                isDeliveryRangeAvailable: false,
                deliveryRange: null,
                deliveryRangeError: action.payload.data
            };

        case SET_USER_LOCATION:
            return {
                ...state,
                userLat: action.payload.lat,
                userLng: action.payload.lng,
                userLocationName: action.payload.locationName
            };
            case CHECK_DELIVERY_SUCCESS:
                return {
                    ...state,
                    deliveryData: action.payload.data.data,
                    deliveryDataError: null,
                };
            case CHECK_DELIVERY_ERROR:
                return {
                    ...state,
                    deliveryData:null,
                    deliveryDataError: action.payload, 
                };

        default:
            return state
    }
};
export default login;