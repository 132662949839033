import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Guest from './Guest';
import { get as _get } from "lodash";
import { getProductList } from '../../Store/action/product'
import { showToastMessage, setStartLoader, setStopLoader, resetToastMessage } from '../../Store/action/loader'
import { getUserProfile, logoutUser } from '../../Store/action/user'
import { openLogin, closeLogin,openSignup } from '../../Store/action/login'
import { getDeliveryRange,openFinetuneLocation,closeFinetuneLocation } from '../../Store/action/location'

function mapStateToProps(state, props) {
    return {
        showLogin : state.login.showLogin,
        showSignup : state.login.showSignup,
        userLocationName : state.location.userLocationName,
        userProfile:_get(state,'user.userProfile',null),
        cartCount: state.product.addToCart ? state.product.addToCart.products.length : 0,
        addToCart: state.product.addToCart,
        productList: state.product.productList,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProductList,
        showToastMessage,
        setStartLoader, 
        setStopLoader,
        resetToastMessage,
        openLogin,
        closeLogin,
        openSignup,
        getUserProfile,
        logoutUser,
        getDeliveryRange,
        openFinetuneLocation,
        closeFinetuneLocation
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Guest);

