import {
    VALIDATE_COUPON_CODE_SUCCESS,
    VALIDATE_COUPON_CODE_ERROR
} from "../actionType";
import { get as _get  } from "lodash";
const INITIAL_STATE = {
    couponSuccess: null,
    couponError: null
};
const cart = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VALIDATE_COUPON_CODE_SUCCESS:
            
            return {
                ...state,
                couponSuccess: action.payload.data,
                couponError: null,
            };
        case VALIDATE_COUPON_CODE_ERROR:
            
            return {
                ...state,
                couponError: _get(action,'payload.response.response.data',null),
                couponSuccess: null,
            };
        default:
            return state
    }
};
export default cart;