import {
    SET_CATEGORY_SUCCESS,
    SET_CATEGORY_ERROR,

    SET_CATEGORY_LIST_SUCCESS,
    SET_CATEGORY_LIST_ERROR
} from "../actionType";
const INITIAL_STATE = {
    category: null,
    categoryError: null
};
const category = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload.data.data,
                categoryError: null,
            };
        case SET_CATEGORY_ERROR:
            return {
                ...state,
                category: action.payload,
                categoryError: null,
            };
        case SET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                category: action.payload.data.data,
                categoryError: null,
            };
        case SET_CATEGORY_LIST_ERROR:
            return {
                ...state,
                category: action.payload,
                categoryError: null,
            };
        default:
            return state
    }
};
export default category;