import React, { Suspense, Fragment, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
// import TopBar from "./Components/TopBar";
import TopBar from "../Components/CommonTopBar";
import Banner from './Components/Banner';
import ProfileSidebar from './Components/ProfileSidebar';
import Loader from '../../Components/Loader';

const UserProfile = (props) => {
  const { route, 
    getProductList, 
    showToastMessage, 
    resetToastMessage, 
    showLogin, 
    openLogin, 
    closeLogin, 
    userProfile, 
    userLocationName, 
    cartCount,
    logoutUser,
    addToCart
  } = props;
  let displayQty = 0;
  addToCart && addToCart.products.map((product) => {
    displayQty = Number(displayQty) + Number(product.quantity);
    return displayQty;
  });
  useEffect(() => {
    const param = {
      order: "created_at"
    }

    /**
     * @param categoryId
     * @param search
     * @param store_id
     * @param sub_category_id
     * @param order
     */
    getProductList(param);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <TopBar cartCount={displayQty} userLocationName={userLocationName} logoutUser={logoutUser} userProfile={userProfile} showToastMessage={showToastMessage} resetToastMessage={resetToastMessage} showLogin={showLogin} openLogin={openLogin} closeLogin={closeLogin} />
      <div>
        <Banner />
        <section class="cat_product_area section_padding">
          <div class="container">
            <div className="row">
              <div class="col-sm-5 col-md-4 col-lg-3">
                <ProfileSidebar userProfile={userProfile}/>
              </div>
              <Suspense fallback={<Loader show={true} />}>
                {renderRoutes(route.routes)}
              </Suspense>
            </div>
          </div>
        </section>
      </div>
     
    </Fragment>
  );
};

UserProfile.propTypes = {
  route: PropTypes.object,
};

export default UserProfile;
