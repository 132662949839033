import React from 'react';

const Loader = ({show}) => {
    return (
        <div class="loader" style={{display:show?"block":"none"}}>
            <div class="loader_inner">
                <div class="loader_content">
                    <img src="/assets/img/loader.gif" class="img-fluid loadingif" alt="loading"/>
                    <div class="loadmore-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        </div>
    );
}

export default Loader;
