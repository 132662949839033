import {
    OPEN_LOGIN,
    CLOSE_LOGIN,
    OPEN_SIGNUP,
    CLOSE_SIGNUP
} from "../../actionType";

export const openLogin = _payload => {
    return { type: OPEN_LOGIN, payload: _payload }
}

export const closeLogin = _payload => {
    return { type: CLOSE_LOGIN, payload: _payload }
}

export const openSignup = _payload => {
    return { type: OPEN_SIGNUP, payload: _payload }
}
export const closeSignup = _payload => {
    return { type: CLOSE_SIGNUP, payload: _payload }
}