import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { getToken, logout } from "../../../utils/token";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    // UncontrolledDropdown,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
    // NavbarText
  } from 'reactstrap';
  import {isMobile} from 'react-device-detect';

const CommonTopBar = ({ openLogin, openSignup,userProfile, userLocationName, cartCount, logoutUser, showToastMessage, 
    resetToastMessage, openFinetuneLocation,
    closeFinetuneLocation }) => {
    const openLoginModal = () => openLogin();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
  
    return (
        <Fragment>
        
            <header className="main_menu home_menu">
                <div class="top_nav">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6 col-md-6">
                                <div class="top_nav_left"><i class="ti-location-pin"></i> <Link href="#" onClick={openFinetuneLocation}>{localStorage.getItem("locationName") ? localStorage.getItem("locationName") : "Your Location"}</Link></div>
                            </div>
                            <div class="col-sm-6 col-md-6 d-none d-sm-block">
                                <div class="top_nav_right">
                                    <ul class="top_nav_menu">
                                        {/* <li class="currency">
                                            <Link href="#">
                                                INR
										<i class="fa fa-angle-down"></i>
                                            </Link>
                                            <ul class="currency_selection">
                                                <li><Link href="#">QAR</Link></li>
                                                <li><Link href="#">INR</Link></li>
                                            </ul>
                                        </li>
                                        <li class="language">
                                            <Link href="#">
                                                EN
										<i class="fa fa-angle-down"></i>
                                            </Link>
                                            <ul class="language_selection">
                                                <li><Link href="#">EN</Link></li>
                                                <li><Link href="#">ARB</Link></li>
                                            </ul>
                                        </li> */}
                                        <li class="account">
                                            <Link href="#">
                                                {userProfile ? `Welcome - ${userProfile.name}` : "Welcome - Guest"}
                                                {userProfile && <i class="fa fa-angle-down"></i>}
                                            </Link>
                                            <ul class="account_selection">
                                                {userProfile &&
                                                    <li><Link href="#" onClick={()=>{
                                                        logout();
                                                        logoutUser('',(response)=>{
                                                            showToastMessage({type:"success",message:"User logout successfully"})
                                                            resetToastMessage();
                                                        },(error)=>{

                                                        })
                                                    }}>Logout</Link></li>
                                                }
                                            </ul>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <Navbar light expand="lg">
                                <NavbarBrand><Link to="/" class="navbar-brand"> <img src="/assets/img/logo.svg" alt="logo" class="mainlogo" /> </Link></NavbarBrand>
                                <NavbarToggler onClick={toggle}><span className="menu_icon"><i className="fas fa-bars"></i></span></NavbarToggler>
                                <Collapse className="main-menu-item" id="navbarSupportedContent" isOpen={isOpen} navbar>
                                    <Nav navbar>
                                        <NavItem>
                                            <NavLink tag={Link} to="/" onClick={()=>isMobile && toggle()}>Home</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/category" onClick={()=>isMobile && toggle()}>Shop Now</NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink tag={Link} to="/sell-with-us" onClick={()=>isMobile && toggle()}>Sell With Us </NavLink>
                                        </NavItem> */}
                                        <NavItem>
                                            <NavLink tag={Link} to="/contact" onClick={()=>isMobile && toggle()}>Contact</NavLink>
                                        </NavItem>
                                        {/* <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                Options
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    Option 1
                                                </DropdownItem>
                                                <DropdownItem>
                                                    Option 2
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>
                                                    Reset
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown> */}
                                    </Nav>
                                    {/* <NavbarText>Simple Text</NavbarText> */}
                                </Collapse>
                                <div className="hearer_icon d-flex">
                                    {/* <Link id="search_1"><i className="ti-search"></i></Link> */}
                                    {getToken() ? <Link to={getToken() && 'user-profile/my-profile'}><i className="ti-user"></i></Link> : <Link onClick={!getToken() && openLoginModal} ><i className="ti-user"></i></Link>}
                                    {/* <Link><i className="ti-settings"></i></Link> */}
                                    <div className="dropdown cart">
                                        <Link to="/cart-order-list" className="dropdown-toggle" id="navbarDropdown3" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-cart-plus"></i><span>{cartCount ? cartCount : 0}</span>
                                        </Link>
                                    </div>
                                </div>
                            </Navbar>
                        </div>
                    </div>
                </div>
                <div className="search_input" id="search_input_box" style={{ display: "none" }}>
                    <div className="container ">
                        <form className="d-flex justify-content-between search-inner">
                            <input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                            <button type="submit" className="btn"></button>
                            <span className="ti-close" id="close_search" title="Close Search"></span>
                        </form>
                    </div>
                </div>
            </header>
        </Fragment>
    );

}


export default CommonTopBar;
