import {
    CONTACT_US_SUCCESS,
    CONTACT_US_ERROR
} from "../actionType";
const INITIAL_STATE = {
    isContactFormSubmitted: false,
    isError: false,
    isSuccess:false,
    contactUsError:null,
};
const contact = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONTACT_US_SUCCESS:
            return {
                ...state,
                isContactFormSubmitted: true,
                isError: false,
                isSuccess:true,
                contactUsError:null,
            };
        case CONTACT_US_ERROR:
         
            return {
                ...state,
                isContactFormSubmitted: true,
                isError: true,
                isSuccess:false,
                contactUsError:action.payload.response.response.data
            };
        default:
            return state
    }
};
export default contact;