import _ from 'lodash';

import {
    SET_NEW_ARRIVAL_PRODUCT_SUCCESS,
    SET_NEW_ARRIVAL_PRODUCT_ERROR,

    SET_CATEGORY_BASED_PRODUCT_SUCCESS,
    SET_CATEGORY_BASED_PRODUCT_ERROR,

    SET_CURRENT_PRODUCT_SUCCESS,
    SET_CURRENT_PRODUCT_ERROR,
    ADD_TO_CART,
    ADD_TO_CART_ITEM_INC,
    ADD_TO_CART_ITEM_DEC,
    ADD_TO_CART_SAVE_ON_CHECKOUT_SUCCESS,
    ADD_TO_CART_SAVE_ON_CHECKOUT_ERROR,
    GET_USER_CART_DETAILS_ERROR,
    GET_USER_CART_DETAILS_SUCCESS,
    ADD_TO_CART_ITEM_INC_SUCCESS,
    ADD_TO_CART_ITEM_INC_ERROR,
    ADD_TO_CART_ITEM_DEC_ERROR,
    ADD_TO_CART_ITEM_DEC_SUCCESS,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_ERROR,

    ADD_FAVORITE_PRODUCT_SUCCESS,
    ADD_FAVORITE_PRODUCT_ERROR,

    GET_FAVORITE_PRODUCT_SUCCESS,
    GET_FAVORITE_PRODUCT_ERROR,
    CART_CHECKOUT_SAVE_SUCCESS
} from "../actionType";
const INITIAL_STATE = {
    newArrivalproduct: null,
    newArrivalproductError: null,
    productList: null,
    productListError: null,
    currentProduct: null,
    currentProductError: null,
    getFavoriteProductSuccess:[],
    getFavoriteProductError:null,

    addFavoriteProductSuccess:null,
    addFavoriteProductError:null,
    addToCart: {
        user: "Guest",
        currency: '',
        isLoggedIn: false,
        isGuestCartSync : false,
        products: []
    }
};
const product = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_NEW_ARRIVAL_PRODUCT_SUCCESS:
            return {
                ...state,
                newArrivalproduct: action.payload.data,
                newArrivalproductError: null,
            };
        case SET_NEW_ARRIVAL_PRODUCT_ERROR:
            return {
                ...state,
                newArrivalproduct: null,
                newArrivalproductError: action.payload,
            };
        case SET_CATEGORY_BASED_PRODUCT_SUCCESS:
            return {
                ...state,
                productList: action.payload.data,
                productListError: null,
            };
        case SET_CATEGORY_BASED_PRODUCT_ERROR:
            return {
                ...state,
                productList: null,
                productListError: action.payload,
            };
        case SET_CURRENT_PRODUCT_SUCCESS:
            return {
                ...state,
                currentProduct: action.payload.data,
                currentProductError: null,
            };
        case SET_CURRENT_PRODUCT_ERROR:
            return {
                ...state,
                currentProduct: null,
                currentProductError: action.payload,
            };
        case ADD_TO_CART: {
            let productInfo = action.payload;
            let _addToCart = _.cloneDeep(state.addToCart);
            let stateProducts = _addToCart.products;

            let productIndex = stateProducts.findIndex(_product => _product.product_id === productInfo.id);
            if (productIndex !== -1) {
                stateProducts[productIndex].quantity = stateProducts[productIndex].quantity + 1;
                stateProducts[productIndex].total_amount = (parseFloat(stateProducts[productIndex].total_amount) + parseFloat(productInfo.price)).toFixed(2);
            } else {
                let tempProduct = {
                    product_id: productInfo.id,
                    quantity: 1,
                    unit_price: parseFloat(productInfo.price).toFixed(2),
                    total_amount: parseFloat(productInfo.price).toFixed(2),
                    product : {
                        name: productInfo.title,
                        currency : productInfo.currency,
                        img : productInfo.img,
                }
                };
                stateProducts.push(tempProduct);
                _addToCart.currency = productInfo.currency;
            }
            return {
                ...state,
                addToCart: _addToCart
            };
        }
        case ADD_TO_CART_SUCCESS: {
            let productInfo = action.payload.data.data[0];
            let _addToCart = _.cloneDeep(state.addToCart);
            let stateProducts = _addToCart.products;

            let productIndex = stateProducts.findIndex(_product => _product.product_id === productInfo.product_id);
            if (productIndex !== -1) {
                stateProducts[productIndex].quantity = stateProducts[productIndex].quantity + 1;
                stateProducts[productIndex].total_amount = (parseFloat(stateProducts[productIndex].total_amount) + parseFloat(productInfo.unit_price)).toFixed(2);
            } else {
                stateProducts.push(productInfo);
                _addToCart.currency = productInfo.currency;
            }
            return {
                ...state,
                addToCart: _addToCart
            };
        }
        case ADD_TO_CART_ITEM_INC: {
            let productId = action.payload;
            let _addToCart = _.cloneDeep(state.addToCart);
            let stateProducts = _addToCart.products;

            let productIndex = stateProducts.findIndex(_product => _product.product_id === productId);
            if (productIndex !== -1) {
                stateProducts[productIndex].quantity = stateProducts[productIndex].quantity + 1;
                stateProducts[productIndex].total_amount = (parseFloat(stateProducts[productIndex].total_amount) + parseFloat(stateProducts[productIndex].unit_price)).toFixed(2);
            }
            return {
                ...state,
                addToCart: _addToCart
            };
        }
        case ADD_TO_CART_ITEM_INC_SUCCESS: {
            let productId = action.payload.data.data[0].product_id;
            let _addToCart = _.cloneDeep(state.addToCart);
            let stateProducts = _addToCart.products;

            let productIndex = stateProducts.findIndex(_product => _product.product_id === productId);
            if (productIndex !== -1) {
                stateProducts[productIndex].quantity = stateProducts[productIndex].quantity + 1;
                stateProducts[productIndex].total_amount = (parseFloat(stateProducts[productIndex].total_amount) + parseFloat(stateProducts[productIndex].unit_price)).toFixed(2);
            }
            return {
                ...state,
                addToCart: _addToCart
            };
        }
        case ADD_TO_CART_ITEM_DEC: {
            debugger;
            let productId = action.payload;
            let _addToCart = _.cloneDeep(state.addToCart);
            let stateProducts = _addToCart.products;

            let productIndex = stateProducts.findIndex(_product => _product.product_id === productId);
            if (productIndex !== -1) {
                if (stateProducts[productIndex].quantity === 1) {
                    stateProducts.splice(productIndex, 1);
                } else {
                    stateProducts[productIndex].quantity = stateProducts[productIndex].quantity - 1;
                    stateProducts[productIndex].total_amount = (parseFloat(stateProducts[productIndex].total_amount) - parseFloat(stateProducts[productIndex].unit_price)).toFixed(2);
                }
            }
            return {
                ...state,
                addToCart: _addToCart
            };
        }
        case ADD_TO_CART_ITEM_DEC_SUCCESS: {
            let productId = action.payload.data.length > 0 ? action.payload.data[0].product_id : action.payload.data.product_id;
            let _addToCart = _.cloneDeep(state.addToCart);
            let stateProducts = _addToCart.products;

            let productIndex = stateProducts.findIndex(_product => _product.product_id === productId);
            if (productIndex !== -1) {
                if (stateProducts[productIndex].quantity === 1) {
                    stateProducts.splice(productIndex, 1);
                } else {
                    stateProducts[productIndex].quantity = stateProducts[productIndex].quantity - 1;
                    stateProducts[productIndex].total_amount = (parseFloat(stateProducts[productIndex].total_amount) - parseFloat(stateProducts[productIndex].unit_price)).toFixed(2);
                }
            }
            return {
                ...state,
                addToCart: _addToCart
            };
        }
        case ADD_TO_CART_SAVE_ON_CHECKOUT_SUCCESS: {
            let _addToCart = _.cloneDeep(state.addToCart);
            _addToCart.isGuestCartSync = true;
            return {
                ...state,
                addToCart : _addToCart
            };
        }
        case ADD_TO_CART_ERROR:
        case ADD_TO_CART_ITEM_INC_ERROR:
        case ADD_TO_CART_ITEM_DEC_ERROR:
        case ADD_TO_CART_SAVE_ON_CHECKOUT_ERROR: {
            return { ...state };
        }
        case GET_USER_CART_DETAILS_SUCCESS: {
            let _addToCart = _.cloneDeep(state.addToCart);
            _addToCart.products = action.payload.data.data;
            _addToCart.currency = _addToCart.products && _addToCart.products[0].product.currency;
            return { ...state, addToCart: _addToCart };
        }
        case GET_USER_CART_DETAILS_ERROR: {
            return { ...state };
        }
        case ADD_FAVORITE_PRODUCT_SUCCESS:
            return {
                ...state,
          
                addFavoriteProductSuccess:action.payload,
                addFavoriteProductError:null,
            };
        case ADD_FAVORITE_PRODUCT_ERROR:
            return {
                ...state,
                addFavoriteProductSuccess:null,
                addFavoriteProductError:action.payload,
            };
        case GET_FAVORITE_PRODUCT_SUCCESS:
            return {
                ...state,
            
                getFavoriteProductSuccess:_.get(action,'payload.data.data',[]),
                getFavoriteProductError:null,
            };
        case GET_FAVORITE_PRODUCT_ERROR:
            return {
                ...state,
                getFavoriteProductSuccess:null,
                getFavoriteProductError:action.payload,
            };
        case CART_CHECKOUT_SAVE_SUCCESS: {
            let _addToCart = _.cloneDeep(state.addToCart);
            _addToCart.products = [];
            _addToCart.currency = '';
            return { ...state, addToCart: _addToCart };
        }
        default:
            return state
    }
};
export default product;