import React, { Suspense, Fragment, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
// import TopBar from "./Components/TopBar";
import TopBar from "../Components/CommonTopBar";
import Footer from "./Components/Footer";
import Loader from '../../Components/Loader';
import { getToken } from '../../utils/token';
import { getDeliveryRangeData } from '../../utils/commonFunction';



const Guest = (props) => {
  const { route,
    getProductList,
    showToastMessage,
    resetToastMessage,
    showLogin,
    openLogin,
    closeLogin,
    openSignup,
    userProfile,
    userLocationName,
    cartCount,
    addToCart,
    logoutUser,
    getDeliveryRange,
    openFinetuneLocation,
    closeFinetuneLocation,
    productList
  } = props;
  let displayQty = 0;
  addToCart && addToCart.products.map((product) => {
    displayQty = Number(displayQty) + Number(product.quantity);
    return displayQty;
  });
  useEffect(() => {
    const param = {
      order: "created_at"
    }
    console.log(getToken());
    if (getToken() && userProfile === null && (!showLogin)) {
      props.getUserProfile()
    }

    /**
     * @param categoryId
     * @param search
     * @param store_id
     * @param sub_category_id
     * @param order
     */
    getProductList(param);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, showLogin]);

  useEffect(() => {
    localStorage.getItem("geo_latitude") && localStorage.getItem("geo_longitude") && getDeliveryRangeData(getDeliveryRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <TopBar openFinetuneLocation={openFinetuneLocation} closeFinetuneLocation={closeFinetuneLocation} cartCount={displayQty} logoutUser={logoutUser} userLocationName={userLocationName} userProfile={userProfile} showToastMessage={showToastMessage} resetToastMessage={resetToastMessage} showLogin={showLogin} openLogin={openLogin} closeLogin={closeLogin} openSignup={openSignup} />
      <Suspense fallback={<Loader show={true} />}>
        {renderRoutes(route.routes)}
      </Suspense>
      <Footer addToCart={addToCart} productList={productList} />
    </Fragment>
  );
};

Guest.propTypes = {
  route: PropTypes.object,
};

export default Guest;
