import {
GET_USER_PROFILE_ERROR,
GET_USER_PROFILE_SUCCESS,
EDIT_USER_PROFILE_ERROR,
EDIT_USER_PROFILE_SUCCESS,
GET_ORDER_LIST_SUCCESS,
GET_ORDER_LIST_ERROR,
ADD_ADDRESS_ERROR,
ADD_ADDRESS_SUCCESS,
GET_ALL_ADDRESS_SUCCESS,
GET_ALL_ADDRESS_ERROR,
EDIT_ADDRESS_SUCCESS,
EDIT_ADDRESS_ERROR,

LOGOUT_USER
} from "../actionType";
const INITIAL_STATE = {
    userProfile: null,
    userProfileError: null,
    
    editUserProfileError: null,
    editUserProfile: null,
    orderListSuccess:[],
    orderListError:null,
    addAddressSuccess:null,
    addAddressError:null,
    allAddressSuccess:[],
    allAddressError:null,
    editAddressSuccess:null,
    editAddressError:null,


};
const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_PROFILE_SUCCESS:
            
            return {
                ...state,
                userProfile: action.payload.data,
                bannerListError: null,
            };
        case GET_USER_PROFILE_ERROR:
            
            return {
                ...state,
                userProfileError: action.payload,
                userProfile: null,
            };
        
        case LOGOUT_USER:
            return {
                ...state,
                userProfileError: null,
                userProfile: null,
            };
            //
            case EDIT_USER_PROFILE_SUCCESS:
            
                return {
                    ...state,
                    userProfileError: action.payload,
                    userProfile: null,
                };
            
            case EDIT_USER_PROFILE_ERROR:
                return {
                    ...state,
                    editUserProfileError: null,
                    editUserProfile: action.payload,
                };
                //
                case GET_ORDER_LIST_SUCCESS:
                
                    return {
                        ...state,
              
                        orderListSuccess:action.payload.data.data,
                        orderListError:null,
                    };
                
                case GET_ORDER_LIST_ERROR:
                    return {
                        ...state,
                       
                        orderListSuccess:[],
                        orderListError:action.payload,
                    };
                    //
                    case ADD_ADDRESS_SUCCESS:
                
                        return {
                            ...state,
                  
                            addAddressSuccess:action.payload,
                            addAddressError:null,
                        };
                    
                    case ADD_ADDRESS_ERROR:
                        return {
                            ...state,
                           
                            addAddressSuccess:null,
                            addAddressError:action.payload,
                        };
                    //
                    case EDIT_ADDRESS_SUCCESS:
                
                        return {
                            ...state,
                  
                            editAddressSuccess:action.payload,
                            editAddressError:null,
                        };
                    
                    case EDIT_ADDRESS_ERROR:
                        return {
                            ...state,
                           
                            editAddressSuccess:null,
                            editAddressError:action.payload,
                        };
                    //
                    case GET_ALL_ADDRESS_SUCCESS:
                
                        return {
                            ...state,
                            allAddressSuccess:action.payload.data.data,
                            allAddressError:null,
                        };
                    
                    case GET_ALL_ADDRESS_ERROR:
                        return {
                            ...state,
                            allAddressSuccess:[],
                            allAddressError:action.payload,
                        };
        default:
            return state
    }
};
export default user;