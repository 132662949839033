import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "./LocationModel.css";

const LocationModal = (props) => {

  const toggle = () => {
    props.closeLocation();
    props.setStopLoader()
  };

  // const [googleMap, setGoogleMap] = useState(false);

  function getLocation() {
    // if (navigator.geolocation) {
    //   props.setStartLoader();
    //   navigator.geolocation.getCurrentPosition(getCoordinates, handleLocationError);
    // } else {
    //   props.showToastMessage({ type: "error", message: "Geolocation is not supported by this browser." });
    //   props.resetToastMessage();
    //   props.setStopLoader();
    // }
  }
  // function getCoordinates(position) {
  //   debugger;
  //   console.log(position)
  //   // setGoogleMap(true)
  //   props.getDeliveryRange({
  //     geo_latitude: position.coords.latitude,
  //     geo_longitude: position.coords.longitude
  //   });
  //   !props.deliverStatus && props.openFinetuneLocation();
  //   toggle();
  // }
  // function handleLocationError(error) {
  //   switch (error.code) {
  //     case error.PERMISSION_DENIED:
  //       props.showToastMessage({ type: "error", message: "User denied the request for Geolocation." });
  //       props.resetToastMessage();
  //       break;
  //     case error.POSITION_UNAVAILABLE:
  //       props.showToastMessage({ type: "error", message: "Location information is unavailable." });
  //       props.resetToastMessage();
  //       break;
  //     case error.TIMEOUT:
  //       props.showToastMessage({ type: "error", message: "The request to get user location timed out." });
  //       props.resetToastMessage();
  //       break;
  //     case error.UNKNOWN_ERROR:
  //       props.showToastMessage({ type: "error", message: "An unknown error occurred." });
  //       props.resetToastMessage();
  //       break;
  //     default:
  //       return null;
  //   }
  //   props.setStopLoader();
  // }

  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      {/* <Modal isOpen={props.show} toggle={toggle} className="locationPopup" size="lg"> */}
      <Modal isOpen={false} toggle={toggle} className="locationPopup" size="lg">
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <div className="modal-body">
            <h2>Location Services</h2>
            <p><img src="/assets/img/location-service.png" alt="location enable" /></p>
            <h4 className="mb-3">Kees uses location services in order to determine proximity of listed business and only while you are using the application</h4>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-dark mr-2" color="primary" onClick={()=>getLocation}>Enable Location Services</Button>{' '}
          {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default LocationModal;