import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const FooterCartDetail = (props) => {

    return (
        <Fragment>
            <Link to="/cart-order-list">
                <div class="viewbasket_data">
                    <div class="container">
                        <div class="row">
                            <div class="col-7 col-sm-6 col-md-6 col-lg-6">
                                Your Cart - {props.qty && props.qty} Products
                        </div>
                            <div class="col-5 col-sm-6 col-md-6 col-lg-6 text-right">
                                {props.price && props.price} QAR
                        </div>
                        </div>
                    </div>
                </div>
            </Link>
        </Fragment>
    );
}


export default FooterCartDetail;
